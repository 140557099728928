import {useRef, useEffect} from 'react';
import {Grid, Typography,Paper} from '@mui/material'
import {css, jsx} from '@emotion/react'
import logoImg1 from "../images/logoImg1.png"
import logoImg2 from "../images/logoImg2.png"
import theme from '../components/theme.js'
import { ThemeProvider } from '@mui/material/styles';
/** @jsx jsx */
const NavSectionStyle = css`
  display:flex;
  justify-content:center; 
  min-height:85px;
  align-items:center;
  & div{
    text-align:center;
  }
  & a:hover{
    cursor:pointer;
    font-weight:bold;
    text-decoration:underline;
  }
  & a{
    text-decoration:none;
    font-family:'Roboto';
    color:black;
    font-size:28px;
  }
`
const SiteHeader = (props)=> {
	let homePageRef = useRef(null)
	let featuredListingsRef = useRef(null)
	let allListingsRef = useRef(null)

	useEffect(()=>{

		const highlightCurPage = (section)=>{
			section.current.style.fontWeight="1000"
		}
		if(props.currentPage==="Home"){
			highlightCurPage(homePageRef)
		}
		else if(props.currentPage==="All listings"){
			highlightCurPage(allListingsRef)
		}
		else if(props.currentPage==="Featured listings"){
			highlightCurPage(featuredListingsRef)
		}
      
	}, [])
	return(
		<ThemeProvider theme={theme}>
			<Paper elevation={8} style={{ width:"100vw",alignItems:"space-between", display:"flex", position:"sticky", right:0, zIndex:1}}>
				<Grid container> 
					<Grid container item xs={12} style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
						<Grid item xs={12} md={8} style={{display:"flex", border:"5px solid #E2221D", alignItems:"center",justifyContent:'center', columnGap:"15px", padding:"15px"}}>
							<img src={logoImg1} style={{width:"7vw", minWidth:"70px", alignSelf:"flex-end"}} alt="logo icon 1"/>
							<Typography variant="h1" style={{ textAlign:"center", fontWeight:"500", color:"#E2221D", fontFamily:"TimesNewRoman"}}>Sunny Audio</Typography>
							<img src={logoImg2} style={{width:"7vw", minWidth:"70px", alignSelf:"flex-end"}} alt="logo icon 2"/>
						</Grid>
						<Grid container item xs={12} css={NavSectionStyle}>
							<Grid item xs={4} md={2}>
								<a href="/" ref={homePageRef}>Home</a>
							</Grid>
							<Grid item xs={4} md={2}>
								<a href="/featuredListings" ref={featuredListingsRef}>Featured Listings</a>
							</Grid>
							<Grid item xs={4} md={2}>
								<a href="/searchAllListings" ref={allListingsRef}>Search All Listings</a>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Paper>
		</ThemeProvider>
	)
}

export default SiteHeader
