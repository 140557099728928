import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const themeBase = createTheme({
	typography: {

		h1:{
			fontSize:70

		},
		body1:{
			fontSize:15

		},
	},
});

const defaultTheme = responsiveFontSizes(themeBase, 
	{
		factor:6
	});

export default defaultTheme
export { themeBase}